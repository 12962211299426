@import '../variables-common.scss';

/* colors */
$color-primary: #003d71;
$color-accent: #009efa;
$color-secondary: $color-blue;
$color-badge: lighten($color-primary, 50%);

/* background */
$main-background-color: $color-primary;
$main-background: linear-gradient(
  225deg,
  rgba($color-primary, 0.3) 0%,
  rgba($color-white, 0.5) 35%,
  rgba($color-accent, 0.2) 100%
);

/* header logo */
$header-logo-width: 255.2px;
$header-logo-suffix-margin-top: 0;
